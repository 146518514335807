$(document).ready(function() {

	var test = false;

	if (test == true){

		// ?utm_source=value_source&utm_medium=value_medium&utm_campaign=value_campaign&utm_term=value_term&utm_content=value_content
		if (document.getElementById('nomesia_form') !== null){
			document.getElementsByName('source')[0].removeAttribute('type');
			document.getElementsByName('medium')[0].removeAttribute('type');
			document.getElementsByName('campaign')[0].removeAttribute('type');
			document.getElementsByName('term')[0].removeAttribute('type');				
		}

	}

	var queryStringParams = {
		'labels' : ['source', 'medium',	'campaign', 'term', 'content'],
		'values' : []			
	};

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}


	function getParameterByName(name, url) {
		if (!url) 
			url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
		if (!results)
			return null;
		if (!results[2])
			return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	function setCookie(Key, Value){
		var now = new Date();
		var time = now.getTime();
		var expireTime = time + 1000*36000;
		now.setTime(expireTime);
  		// var tempExp = 'Wed, 31 Oct 2012 08:50:17 GMT';
  		document.cookie = Key+'='+Value+';expires='+now.toGMTString()+';path=/';
  	}

  	function storeQueryParamsFrm(){
  		for(var i = 0; i < queryStringParams.labels.length; i++){
  			var value = getParameterByName('utm_'+queryStringParams.labels[i]);
  			console.log(value);
			if ((value  != null) && (value  != 'undefined') ) {
				queryStringParams.values[i] = value;
				setCookie(queryStringParams.labels[i],value);
			}
		}
	}
	
	storeQueryParamsFrm();

	if (document.getElementById('nomesia_form') !== null)		
		setTimeout(function(){ 
			for(var i = 0; i < queryStringParams.labels.length; i++){
				console.log("label:" + queryStringParams.labels[i] + " value from cookie: "+getCookie(queryStringParams.labels[i]));
				document.getElementsByName(queryStringParams.labels[i])[0].value = getCookie(queryStringParams.labels[i]);
				console.log( document.getElementsByName(queryStringParams.labels[i])[0].value);
			}
		}, 3000);



	// MOSTRA CAMPO TIPOLOGIA_LEAD SOLO SE ESISTE PIU' DI 1 TIPOLOGIA

	var first = $('#tipologia_lead').find('option').eq(0).val();

	if (first != '') {
		var n = $('#tipologia_lead option').length;
		if (n>1) {
			$('#tipologia_lead_container').removeClass('d-none');
			$('<option value="">Seleziona...</option>').insertBefore($('#tipologia_lead option:first-child'));
			$('#tipologia_lead option:first-child').attr('selected',true);
			$('#tipologia_lead').addClass('required');
			$('<div class="field_status">').insertAfter('#tipologia_lead');
			$("#tipologia_lead").val($("#tipologia_lead option:first").val());
		} else {
			$('#tipologia_lead_container').addClass('d-none');
		}
	};


	// FORM REPLACE

	$('input[type="submit"]').prop("value", "Richiedi una consulenza gratuita");
	$(".required").parent('div').find('label').append('<span class="ast">*</span>');



	// SMOOTH SCROLL
	$('a[href^="#"]:not([href="#"]').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
	});



	// BACK TO TOP

	function detectPosition(top){
		if($(window).scrollTop() > top){
			return 1
		}else{
			return 0;
		}
	}
	
	function fadeTop(x){
		if(x==1){
			$('#top').fadeIn(300)
		}else{
			$('#top').fadeOut(300)
		}
	}

	function actionTop(top){
		x = detectPosition(top);
		fadeTop(x)
	}

	if($('#top').length > 0){

		var top = 220;
		var x = 0;
		actionTop(top)

		$(window).scroll(function(){
			actionTop(top)
		})

		$('#top').click(function(e){
			e.preventDefault();
			$('html,body').animate({'scrollTop':0},750);
		});
	};

});